import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Message from './layout/message';
import Profile from './layout/profile';
import Otp from './layout/otp';
import PrivateRoute from './PrivateRoute';
import './App.scss';

function App() {
  window.newSession = true;

  return (
    <Router>
      <Switch>
        <Route exact path="/profile/:matchId/:userId">
          <PrivateRoute>
            <Otp />
          </PrivateRoute>
        </Route>
        <Route exact path="/message/:matchId/:userId">
          <PrivateRoute page="msg">
            <Message />
          </PrivateRoute>
        </Route>
        <Route path="*">
          <div style={{ textAlign: 'center', marginTop: '30%' }}>
            <img src="/images/no_profile_ic.svg" alt="Not found" />
            <h2 style={{ color: '#FF2978', fontStyle: 'italic', fontWeight: 'bold', marginBottom: '20px' }}>We are sorry,</h2>
            <p style={{ color: '#575757', fontStyle: 'italic', fontWeight: 'bold' }}>this profile is no longer active.</p>
          </div>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
