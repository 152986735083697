import * as firebase from 'firebase';
import firestore from 'firebase/firestore';

const config = {
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID, 
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL
  };
firebase.initializeApp(config);

firebase.firestore();

export default firebase;