import React from 'react';
import styled from 'styled-components';

const Header = props => {
  return (
    <HeaderWrapper {...props}>
      <img src="/images/icons_logo.svg" alt="logo" />
    </HeaderWrapper>
  );
};

export default Header;

const HeaderWrapper = styled.div`
  text-align: center;
  margin: ${props => (props.margin ? props.margin : '15px auto')};
  img {
    width: 71.5px;
    height: 68px;
  }

  @media screen and (max-width: 420px) {
    margin: 10px auto;
    img {
      width: 50.5px;
      height: 48px;
    }
  }

  @media screen and (max-width: 230px) {
    margin: 5px auto;
    img {
      width: 24px;
      height: 23px;
    }
  }
`;
