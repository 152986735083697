import React from 'react';
import styled from 'styled-components';

const otpInput = (e) => {
	e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
}

const otpKeyPress = (e) => {
	if (e.target.value.length === 4) {
		e.preventDefault();
		return false;
	}
}

function stopCarret(e) {
	e.target.scrollLeft = 0;
	if (e.target.value.length > 3){
		setCaretPosition(e.target, 4);
	}
}

function setCaretPosition(elem, caretPos) {
    if(elem != null) {
        if(elem.createTextRange) {
            var range = elem.createTextRange();
            range.move('character', caretPos);
            range.select();
        }
        else {
            if(elem.selectionStart) {
                elem.focus();
                elem.setSelectionRange(caretPos, caretPos);
            }
            else
                elem.focus();
        }
    }
}

export default props => {
  // const newEntry = localStorage.getItem('newEntry');
  // localStorage.setItem('newEntry', 0);
  window.newSession = false;

  return (
    <WelcomeWrapper>
      <CloseIcon onClick={props.handleClose} />
      <div className="content">
        <div className="logo">
          <img src="/images/icons_logo.svg" alt="logo" />
        </div>
        <h1>
          <span>Hi</span> {props.name}
        </h1>
        {false ? (
          <p>Your date is here.</p>
        ) : (
          <>
            <p>We found a match!</p>
            <div className="line" />
          </>
        )}

		{props.otpVerified === 0 ? (
			<>
				<p className="otp__text">
					We sent you a code via sms<br />
					please type it here
				</p>

				<div className="otp-wrapper-outer">
					<div className="otp-wrapper-inner">
						<input type="text" inputMode="numeric" className="otp__input" maxLength="4" onInput={otpInput} onKeyPress={otpKeyPress} onChange={props.handleInput} onKeyDown={stopCarret} onKeyUp={stopCarret} />
					</div>
				</div>
				{props.otpWrong ? <p className="wrong-code">Wrong code, please try again</p> : <p className="wrong-code"></p>}
			</>
			) : null
		}


        <button onClick={props.handleClose}>Show me</button>
      </div>
    </WelcomeWrapper>
  );
};

const WelcomeWrapper = styled.div`
  text-align: center;
  background: #fff;
  color: #737373;
  border-radius: 24px;
  box-shadow: 0px 0px 17px 1px rgba(169, 169, 169, 0.52);
  padding-bottom: 68px;

  .otp__text {
	  font-size: 1rem;
	  text-align: center;
	  line-height: 1.6;
	  margin-bottom: 1.5rem;
  }

  .otp-wrapper-outer {
	  width: 180px;
	//   overflow: hidden;
	  margin: auto;
	  text-align: center;
  }

  .otp-wrapper-inner {
	  left: 0;
	  position: sticky;
  }

  .otp__input {
	outline: none;
	// padding-left: 13px;
	letter-spacing: 38px;
	border: 0;
	background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
	background-position: bottom;
	background-size: 50px 1px;
	background-repeat: repeat-x;
	background-position-x: 35px;
	width: 100%;
	// width: 195px;
	// min-width: 195px;
	font-family: inherit;
	font-size: 1.6rem;
  }

  .wrong-code {
	font-size: 1rem;
	color: red;
	margin-bottom: 1.3rem;
  }

  .content {
    margin: 0 auto;
    width: max-content;
    display: flex;
    flex-direction: column;
	width: 90%;
  }

  .logo {
    text-align: center;
    margin: 50px auto 32px;
    img {
      width: 86px;
      height: 81px;
    }
  }

  h1 {
    font-family: 'Montserrat-Bold';
    color: #101010;
    font-size: 43px;
    letter-spacing: 0.02em;
    line-height: 47px;
	word-break: break-word;
    span {
      color: #ff1b57;
    }
  }

  p {
    font-family: 'Montserrat-Medium';
    font-size: 29px;
    color: #101010;
    letter-spacing: 0.02em;
    line-height: 41px;
    margin-top: 12px;
  }

  .line {
    justify-content: center;
    margin: 45px auto 30px;
    height: 1px;
    width: 54px;
    border-top: 1px solid #101010;
  }

  button {
    padding: 15px;
    border-radius: 38px;
    border: none;
    box-shadow: 0 3px 0px 0px #fddf33, 0 11px 23px 0px rgba(255, 27, 87, 0.35);
    background-image: -webkit-linear-gradient(-46deg, #ff1b57 0%, #ff0085 100%);
    background-image: -moz-linear-gradient(-46deg, #ff1b57 0%, #ff0085 100%);
    background-image: -o-linear-gradient(-46deg, #ff1b57 0%, #ff0085 100%);
    background-image: linear-gradient(-46deg, #ff1b57 0%, #ff0085 100%);

    outline: none;
    color: #ffffff;
    font-size: 24px;
    font-family: 'Montserrat-Bold';
    line-height: 45px;
  }

  @media screen and (max-width: 420px) {
    border-radius: 17px;
    box-shadow: 0px 0px 12px 1px rgba(169, 169, 169, 0.52);
    padding-bottom: 48px;

    .logo {
      margin: 30px auto 18px;
      img {
        width: 66px;
        height: 63px;
      }
    }

    h1 {
      font-size: 30px;
      line-height: 32px;
    }

    p {
      font-size: 20px;
      line-height: 28px;
      margin-top: 8px;
    }

    .line {
      margin: 31px auto 38px;
      height: 1px;
      width: 37px;
      border-top: 1px solid #101010;
    }

    button {
      padding: 10px;
      border-radius: 26px;
      box-shadow: 0 2px 0px 0px #fddf33, 0 8px 16px 0px rgba(255, 27, 87, 0.35);

      font-size: 17px;
      line-height: 31px;
    }
  }

  @media screen and (max-width: 230px) {
    border-radius: 8px;
    box-shadow: 0px 0px 6px 0px rgba(169, 169, 169, 0.52);
    padding-bottom: 22px;

    .logo {
      margin: 9px auto 3px;
      img {
        height: 25px;
        width: 26px;
      }
    }

    h1 {
      font-size: 14px;
      line-height: 16px;
    }

    p {
      font-size: 10px;
      line-height: 14px;
      margin-top: 4px;
    }

    .line {
      margin: 15px auto 18px;
      height: 0px;
      width: 18px;
      border-top: 1px solid #101010;
    }

    button {
      padding: 5px;
      border-radius: 12px;
      box-shadow: 0 1px 0px 0px #fddf33, 0 4px 8px 0px rgba(255, 27, 87, 0.35);

      font-size: 8px;
      line-height: 15px;
    }
  }
`;

const CloseIcon = styled.span`
  background: url(/images/icons_main_menu.svg);
  background-size: contain;
  display: inline-block;
  height: 36px;
  width: 36px;
  position: absolute;
  top: 20px;
  right: 22px;
  cursor: pointer;

  @media screen and (max-width: 420px) {
    height: 26px;
    width: 26px;
    top: 13px;
    right: 14px;
  }

  @media screen and (max-width: 230px) {
    height: 13px;
    width: 13px;
    top: 6px;
    right: 7px;
  }
`;
