import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import './modal.scss';

const Modal = props => {
  const [showModal, setShowModal] = useState(false);
  const {
    isOpen,
    handleClose,
    shouldCloseOnOverlayClick = false,
    children,
  } = props;

  useEffect(() => {
    setShowModal(isOpen);
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => (document.body.style.overflow = 'unset');
  }, [isOpen]);

  return (
    <ReactModal
      className="modal-content"
      isOpen={showModal}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      ariaHideApp={false}
      style={{
        overlay: {
          background: `${
            props.overlayBackground ?? 'rgba(255, 255, 255, 0.85)'
          }`,
        },
        content: {
          bottom: `${props.position === 'bottom' ? '0' : 'auto'}`,
          left: '50%',
          marginRight: '-50%',
          maxWidth: '480px',
          outline: 0,
          position: 'absolute',
          right: 'auto',
          top: `${props.position === 'bottom' ? 'auto' : '50%'}`,
          transform: `${props.position === 'bottom' ? 'translate(-50%)' : 'translate(-50%, -50%)'}`,
          width: '85%',
        },
      }}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
