import React from 'react';
import styled from 'styled-components';

export default props => {
  return (
    <ExpireWrapper>
      {/* <CloseIcon onClick={props.handleClose} /> */}
      <span className="sorry">We are sorry,</span>
      <br />
      <span>it took you too long.</span>
      <br />
      <span>Chat is available for 72 hours only.</span> <br />
      <span className="see">See you next time!</span>
    </ExpireWrapper>
  );
};

const ExpireWrapper = styled.div`
  background: #fff;
  border-radius: 27px 27px 0 0;

  color: #575757;
  font-family: 'Montserrat-Medium';
  font-size: 21px;
  line-height: 34px;
  padding: 27px 0;
  text-align: center;

  .sorry {
    font-family: 'Montserrat-Bold';
  }

  .see {
    color: #ff1b57;
  }

  @media screen and (max-width: 420px) {
    border-radius: 19 px 19  px 0 0;
    
    font-size: 15px;
    line-height: 24px;
    padding: 20px 0;
  }

  @media screen and (max-width: 230px) {
    border-radius: 9px 9px 0 0;

    font-size: 7px;
    line-height: 11px;
    padding: 10px;

  }
`;

const CloseIcon = styled.span`
  background: url(/images/icons_main_menu.svg);
  background-size: contain;
  display: inline-block;
  height: 36px;
  width: 36px;
  position: absolute;
  top: 20px;
  right: 22px;
  cursor: pointer;

  @media screen and (max-width: 420px) {
    height: 26px;
    width: 26px;
    top: 13px;
    right: 14px;
  }

  @media screen and (max-width: 230px) {
    height: 13px;
    width: 13px;
    top: 6px;
    right: 7px;
  }
`;
