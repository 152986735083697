import Api from './api';
import moment from 'moment';

class CounterService {
  startCounter = async (matchId, userId) => {
    let res = await Api.post(`api/chat/v1/channels/${matchId}/${userId}/ack`);
    if (res) {
      return { date: moment().add(3, 'days') };
    }

    return false;
  };
}

export default new CounterService();
