import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import authService from '../services/authService';

const calculateTimeLeft = expireDate => {
  const difference = expireDate - new Date();

  if (difference > 0) {
    return {
      h: Math.floor(difference / (1000 * 60 * 60)),
      m: String(Math.floor((difference / 1000 / 60) % 60)).padStart(2, '0'),
      s: Math.floor((difference / 1000) % 60),
    };
  }

  return false;
};

const Header = props => {
  const { matchId, userId } = useParams();
  let expireDate = new Date(authService.expireDate);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(expireDate));
  const [profileData, setProfileData] = useState(authService.userInfo);
  const [partnerData, setPartnerData] = useState(authService.partnerInfo);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(expireDate));
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <HeaderWrapper hideName={props.hideName}>
      <div className="bar">
        <Link to={`/profile/${matchId}/${userId}`}>
          <img className="back" src="/images/icon_back.svg" alt="back" />
        </Link>
        <img className="logo" src="/images/top_logo_web.svg" alt="logo" />
      </div>

      <div className="counter">
	{ timeLeft ? (
            <div className="pretext">The chat will expire in</div>
        ) : ( <span></span> ) }
        {timeLeft ? (
          <div className="content">
            {timeLeft.h}h : {timeLeft.m}m
          </div>
        ) : (
          <div className="content">Time's up!</div>
        )}
        <div style={props.hideName ? { display: 'none' } : {}}>
          <h2>Message Board</h2>
          <h3>
            {profileData.name} & {partnerData.name}
          </h3>
        </div>
      </div>
    </HeaderWrapper>
  );
};

export default Header;

const HeaderWrapper = styled.div`
  flex: 1;

  .bar {
    max-height: 64px;
    height: 64px;
    width: 100%;
    background-color: #ffffff;
    box-shadow: ${props =>
      props.hideName ? '' : '0px 1px 16px 0px rgba(164, 164, 164, 0.14)'};
  }

  .back {
    width: 35px;
    height: 35px;
    padding: 15px;
  }

  .logo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;

    height: 99px;
    width: 99px;
  }

  .counter {
    padding: ${props => (props.hideName ? '58px 0 22px' : '70px 0 18px')};
    box-shadow: ${props =>
      props.hideName ? '0px 8px 9px 0px rgba(0, 0, 0, 0.03)' : ''};

    .pretext {
        color: #a1a1a1;
        display: block;
        text-align: center;
        margin-bottom: 7px;
        font-size: 15px;
    }

    .content {
      width: 134px;
      background-color: rgba(255, 27, 87, 0.1);

      border-radius: 19px;

      margin: 0 auto;
      padding: 4px;

      font-family: 'OpenSans-Semibold';
      font-size: 19px;
      color: #ff1b57;
      text-align: center;
      line-height: 27px;
    }
  }

  h2 {
    margin-top: 18px;
    font: 23px 'Montserrat-SemiBold';
    color: #16172d;
    text-align: center;
    line-height: 31px;
  }

  h3 {
    font: 18px 'Montserrat-Regular';
    color: #767790;
    text-align: center;
    line-height: 31px;
  }

  @media screen and (max-width: 420px) {
    .bar {
      height: 44px;
      max-height: 44px;
    }

    .back {
      width: 24px;
      height: 24px;
      padding: 10px;
    }

    .logo {
      height: 69px;
      width: 69px;
    }

    .counter {
      padding: ${props => (props.hideName ? '27px 0 11px' : '48px 0 12px')};

      .content {
        border-radius: 19px;
        width: 93px;
        margin: 0 auto;
        padding: 3px;
        font-size: 13px;
        line-height: 19px;
      }
    }

    h2 {
      margin-top: 9px;
      font-size: 16px;
      line-height: 22px;
    }

    h3 {
      font-size: 13px;
      line-height: 22px;
    }
  }

  @media screen and (max-width: 230px) {
    .bar {
      height: 22px;
      max-height: 22px;
    }

    .back {
      width: 12px;
      height: 12px;
      padding: 5px;
    }

    .logo {
      height: 33px;
      width: 33px;
    }

    .counter {
      padding: ${props => (props.hideName ? '14px 0 5px' : '20px 0 6px')};

      .content {
        border-radius: 6px;
        width: 45px;
        margin: 0 auto;
        padding: 2px;
        font-size: 6px;
        line-height: 9px;
      }
    }

    h2 {
      margin-top: 6px;
      font-size: 8px;
      line-height: 10px;
    }

    h3 {
      font-size: 6px;
      line-height: 10px;
    }
  }
`;
