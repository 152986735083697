import React, { useState } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import Header from '../component/header';
import Modal from '../component/modal';
import Gallery from '../component/gallery';
import Welcome from '../component/welcome';
import Expired from '../component/expired';
import StickyButton from '../component/stickyButton';
import authService from '../services/authService';
import counterService from '../services/counterService';

const Otp = () => {
  const [welcomeState, setWelcomeState] = useState(window.newSession);
  // const [welcomeState, setWelcomeState] = useState(false);
  const [galleryState, setGalleryState] = useState(false);
  const [wrongOtp, setWrongOtp] = useState(false);
  const [tacAccepted, setTacAccepted] = useState(false);
  const [ppAccepted, setPpAccepted] = useState(false);

  const [expireState, setExpireState] = useState(
    authService.expireDate && new Date(authService.expireDate) - new Date() <= 0
      ? true
      : false,
  );

  // const [expireState, setExpireState] = useState(false);
  let otpCode = 0;

  const setOtpInput = (e) => {
	  otpCode = e.target.value
  }

  const toggleTac = (e) => {
    setTacAccepted(e.target.checked)
  }

  const togglePp = (e) => {
      setPpAccepted(e.target.checked)
  }

  const profileData = authService.userInfo;
  const partnerData = authService.partnerInfo;

  const { matchId, userId } = useParams();
  const history = useHistory();


  const valuesTranslate = [
    { text: 'Independence', value: 'independence' },
    { text: 'Creativity', value: 'creativity' },
    { text: 'Family', value: 'family' },
    { text: 'Kindness', value: 'kindness' },
    { text: 'Achievement', value: 'achievement' },
    { text: 'Open Mindedness', value: 'open_mindedness' },
    { text: 'Perfection', value: 'perfection' },
    { text: 'Spontaniety', value: 'spontaniety' },
    { text: 'Adventure', value: 'adventure' },
    { text: 'Optimism', value: 'optimism' },
    { text: 'Dependability', value: 'dependability' },
    { text: 'Friends', value: 'friends' },
  ];

  let valuesHtml = `<h2 class="mb1">${partnerData.name}'s Values</h2>`;
  if (partnerData.values !== null && partnerData.values.length > 0) {
    const valuesTexts = partnerData.values.map(x => {
      const value = valuesTranslate.find(y => x === y.value);
      if (typeof value === 'undefined') {
        return null;
      }
      return `
      <div class="info-type">
        <img class="values-img" src="/images/value_check.svg" />
        <p>${value.text}</p>
      </div>
      `
    }).filter(y => y);

    valuesHtml += valuesTexts.join('');
  } else {
    valuesHtml = null;
  }

  const mainPhotoIndex = partnerData.photos.findIndex( x => x.id == partnerData.main_photo.id )
  if ( mainPhotoIndex === -1 ) {
	  partnerData.photos = [
		  partnerData.main_photo,
		  ...partnerData.photos
	  ];
  }

  const representsTranslate = {
    time: [
      { text: 'Wait, what time is it?', value: 'wait_what_time_is_it', img: 'time_loosy.svg' },
      { text: 'Punctual', value: 'punctual', img: 'time_punctual.svg' },
    ],
    organization: [
      { text: 'Gotta have a plan', value: 'gotta_have_a_plan', img: 'organization_planner.svg' },
      { text: 'Mr./Ms. Spontaneous', value: 'mr_ms_spontaneous', img: 'organization_spontaneous.svg' },
    ],
    free_time: [
      { text: 'Out on the couch', value: 'out_on_the_couch', img: 'freetime_couch.svg' },
      { text: 'Out on the town', value: 'out_on_the_town', img: 'freetime_out.svg' },
    ]
  };

  let representsHtml = `<h2 class="mb1">Best represents ${partnerData.name}</h2>`;
  if (partnerData.represents !== null && Object.keys(partnerData.represents).length > 0) {
    const representsTexts = Object.keys(partnerData.represents).map(x => {
      if (typeof representsTranslate[x] === 'undefined') {
        return null;
      }

      const value = representsTranslate[x].find(y => partnerData.represents[x] === y.value);
      if (typeof value === 'undefined') {
        return null;
      }

      return `
      <div class="info-type info-type--underline-dotted">
        <img class="represents-img" src="/images/${value.img}" />
        <p>${value.text}</p>
      </div>
      `
    }).filter(y => y);

    representsHtml += representsTexts.join('')
  } else {
    representsHtml = null;
  }

  let photosHtml = null;
  if (partnerData.photos !== null && partnerData.photos.length > 0) {
    photosHtml = '<div class="gallery-crop mb2"><div class="gallery">';

    const photos = partnerData.photos.map(x => {
      return `
      <figure>
        <img src="${x.url}" />
        <!--<figcaption>Some description asdklfjaslkdjfaskdjflaksdjflkasjdlkasjfd</figcaption>-->
      </figure>
      `
    })

    photosHtml += photos.join('') + '</div></div>';
  }

  function cmToFeet(n) {
    var realFeet = ((n*0.393700) / 12);
    var feet = Math.floor(realFeet);
    var inches = Math.round((realFeet - feet) * 12);
    return feet + "′" + inches + '″';
  }

  // default is 'cm'
  let height = partnerData.height + ' cm'
  if (authService.height_value !== 'cm') {
    const heightValue = partnerData.height_unit_values.find(x => x.unit === authService.height_value)
    if (typeof heightValue !== 'undefined') {
      height = cmToFeet(heightValue.value)
    }
  }

  let masaHtml = null;
  if (partnerData.masa_flag === 1) {
    masaHtml = '<img class="masa-img" src="/images/masa.png" alt="Masa">';
  }

  const clickChat = async () => {
    const data = await counterService.startCounter(matchId, userId);
    if (data) {
      !authService.expireDate && authService.setExpireDate(data.date);
      history.push(`/message/${matchId}/${userId}`);
    }
  };

  const verifyOtp = async () => {
  	if (profileData.otp_verified === 1) {
  		setWelcomeState(false)
  	} else {
  		const isVerified = await authService.isValidOtp(profileData.id, otpCode)
  		setWrongOtp(!isVerified)
  		if (isVerified) {
  			setWelcomeState(false)
  		}
  	}
  }

  const acceptTac = async () => {
    const accept = await authService.acceptTac(matchId, userId);
    if (accept && accept.status === 200) {
      window.location.reload(false);
    }
  }

  const hideTac = () => {
      document.getElementById('tac_container').classList.add('is-hidden')
      document.getElementById('pp_container').classList.remove('is-hidden')
  }

  if (profileData.tac_dt === null) {
    return (
      <>
        <OtpWrapper>
          <div id="pp_container" className="is-hidden">
            <div className="tac-container mb1">
              <iframe className="tac" src="https://www.mamaknowsbest.me/home/SiteContent/privacy" height="100%" width="100%" frameBorder="0"></iframe>
            </div>
            <label className="mb1">
              <input className="mb1" type="checkbox" onClick={togglePp} />
              I agree to the Privacy Policy
            </label>
            <div className="btn-wrap">
              <button id="pp" onClick={acceptTac} disabled={!ppAccepted}>Continue</button>
            </div>
          </div>

          <div id="tac_container">
            <div className="tac-container mb1" id="tac_container">
              <iframe className="tac" src="https://www.mamaknowsbest.me/home/SiteContent/SinglesTerms" height="100%" width="100%" frameBorder="0"></iframe>
            </div>
            <label className="mb1">
              <input className="mb1" type="checkbox" onClick={toggleTac} />
              I agree to the Terms & Conditions
            </label>
            <div className="btn-wrap">
              <button id="tac" onClick={hideTac} disabled={!tacAccepted}>Continue</button>
            </div>
          </div>
        </OtpWrapper>
      </>
    )
  }


  return (
    <>
      <OtpWrapper>
        <Header />
        <div className="card">
          <div
            className="avatar"
            onClick={() => {
              partnerData.photos.length && setGalleryState(true);
            }}
          >
            <img
              src={
                partnerData.main_photo
                  ? partnerData.main_photo.url
                  : `/images/${partnerData.gender}_avatar.svg`
              }
              alt="avatar"
            />
            <div className="profile-info">
              <span className="name">
                {partnerData.name} {partnerData.surname}
              </span>
              <span className="age">Age {partnerData.age}</span>
	  <span className="address">{partnerData.country.country_name}, {partnerData.city.city}</span>
            </div>
            { partnerData.photos.length > 0 ? <span className="photo-count"><span className="c-accent">1</span> / {partnerData.photos.length}</span> : '' }
          </div>
          <div className="desc">
            <div className="says-title">
              <h2 className="pos-r underline bold">MaMa says</h2>
              <span dangerouslySetInnerHTML={{__html: masaHtml}}></span>
            </div>
            <p>{partnerData.description || '-'}</p>
          </div>

          <div className="info info--underline">
            <div className="info-type">
              <img className="details-img" src="/images/icon_status.svg" alt="Status" />
              <p>{partnerData.family_status.charAt(0).toUpperCase() + partnerData.family_status.slice(1)}</p>
            </div>

            <div className="info-type">
              <img className="details-img" src="/images/icon_job.svg" alt="job" />
              <p className={!partnerData.work.description ? 'disabled' : ''}>
                {partnerData.work.description || 'No info'}
              </p>
            </div>
            
            <div className="info-type">
              <img className="details-img" src="/images/icon_study.svg" alt="study" />
              <p
                className={!partnerData.education.description ? 'disabled' : ''}
              >
                {partnerData.education.description || 'No info'}
              </p>
            </div>
            <div className="info-type">
              <img className="details-img" src="/images/icon_height.svg" alt="height" />
              <p className={!partnerData.height ? 'disabled' : ''}>
                {height ? height : 'No info'}
              </p>
            </div>
          </div>

          <div className="info" dangerouslySetInnerHTML={{__html: valuesHtml}}>
          </div>

          <div className="info" dangerouslySetInnerHTML={{__html: representsHtml}}>
          </div>

          <div className="btn-wrap">
            <button onClick={clickChat}>Meet, connect, enjoy</button>
          </div>
        </div>

        <Modal
          isOpen={expireState}
          handleClose={() => setExpireState(false)}
          overlayBackground="rgba(194, 194, 194, 0.39)"
          position="bottom"
        >
          <Expired handleClose={() => setExpireState(false)} />
        </Modal>

        <Modal
          isOpen={galleryState}
          handleClose={() => setGalleryState(false)}
          overlayBackground="rgba(0, 0, 0, 0.75)"
          shouldCloseOnOverlayClick
        >
          <Gallery main_photo={partnerData.main_photo} photos={partnerData.photos} />
        </Modal>

        <Modal
          isOpen={!expireState && welcomeState}
          handleClose={() => setWelcomeState(false)}
          overlayBackground="rgba(224, 224, 224, 0.48)"
        >
          <Welcome
            name={profileData.name}
			otpVerified={profileData.otp_verified}
			otpWrong={wrongOtp}
            handleClose={verifyOtp}
			handleInput={setOtpInput}
          />
        </Modal>
      </OtpWrapper>
    </>
  );
};

export default Otp;

const OtpWrapper = styled.div`
  padding: 0 30px 39px;

  .is-hidden {
      display: none !important;
  }

  .masa-img {
    width: 90px;
    margin-top: 5px;
  }

  .c-accent {
    color: rgba(255, 27, 87, 1); 
  }

  .says-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }

  .bold {
    font-family: 'Montserrat-Bold';
    font-weight: 600;
  }

  .tac-container {
    overflow: hidden;
    height: 79vh;
  }

  .tac {
    width: 100%;
    height: 100%;
    border: 0;
  }

  .flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .ml1 {
    margin-left: .5rem;
  }

  .mb1 {
    margin-bottom: 1rem;
  }

  .card {
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 27px;
    max-width: 480px;
    margin: auto;
    padding: 30px;
  }

  .avatar {
    position: relative;
    margin-bottom: 24px;

    img {
      max-height: 412px;
      width: 100%;
      border-radius: 24px;
      vertical-align: middle;
      object-fit: cover;
    }

    &:before {
      background-image: -webkit-linear-gradient(
        -180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(30, 30, 30, 0.64) 100%
      );
      background-image: -moz-linear-gradient(
        -180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(30, 30, 30, 0.64) 100%
      );
      background-image: -o-linear-gradient(
        -180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(30, 30, 30, 0.64) 100%
      );
      background-image: linear-gradient(
        -180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(30, 30, 30, 0.64) 100%
      );
      border-radius: 24px;
      bottom: 0;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  .profile-info {
    bottom: 26px;
    left: 39px;
    position: absolute;
    > span {
      color: #fff;
      display: block;
      font-family: 'Montserrat-Regular';
      font-size: 24px;
      line-height: 38px;
    }
    .name {
      font-family: 'Montserrat-Bold';
      font-size: 34px;
    }
  }

  .desc {
    margin-bottom: 56px;
    overflow-wrap: break-word;

    h2 {
      margin: 0 0 10px;
      font-family: 'Montserrat-Bold';
      font-weight: 500;
      font-size: 24px;
      color: #101010;
      line-height: 38px;
    }

    p {
      font-size: 23px;
      color: #575757;
      line-height: 38px;
    }
  }

  .details-img {
    width: 25px;
    margin-right: 20px; 
  }

  .values-img {
    width: 20px;
    margin-right: 20px;
  }

  .represents-img {
    width: 28px;
    margin-right: 20px;
  }

  .info {
    position: relative;
    margin-bottom: 56px;

    h2 {
      font-weight: bold;
      margin-bottom: 2.5rem;
      font-size: 23px;
      font-family: 'Montserrat-Bold';
    }

    p {
      font-weight: 500;
      font-size: 23px;
      color: #101010;
      letter-spacing: 0.02em;
      line-height: 36px;
      font-family: 'Montserrat-Medium';
    }

    .info-type {
      display: flex;
      margin-bottom: 27px;
      position: relative;
      align-items: center;

      img.smaller {
        width: 30px;
        height: 30px;
        margin-top: 3px;
      }
    }

    .info-type--underline-dotted:after {
      position: absolute;
      content: '';
      width: 100%;
      border: 1px dashed #e4e4e4;
      left: 50%;
      transform: translate(-50%);
      top: -18px;
    }

    .info-type--underline-dotted {
      margin-bottom: 35px !important;
    }

    .info-type--underline-dotted:first-of-type:after {
      border: 0;
    }

    .disabled {
      font-family: 'Montserrat-Medium';
      color: #c5c5c5;
    }
  }

  .info--underline:after {
    position: absolute;
    content: '';
    background-color: #e4e4e4;
    width: 100%;
    height: 1px;
    left: 50%;
    transform: translate(-50%);
    bottom: -32px;
  }

  a {
    text-decoration: none;
  }

  .btn-wrap {
    display: flex;
    flex-direction: column;
  }

  button {
    margin-bottom: 30px;
    padding: 15px;
    border-radius: 38px;
    border: none;
    box-shadow: 0 3px 0px 0px #fddf33, 0 11px 23px 0px rgba(255, 27, 87, 0.35);
    background-image: -webkit-linear-gradient(-46deg, #ff1b57 0%, #ff0085 100%);
    background-image: -moz-linear-gradient(-46deg, #ff1b57 0%, #ff0085 100%);
    background-image: -o-linear-gradient(-46deg, #ff1b57 0%, #ff0085 100%);
    background-image: linear-gradient(-46deg, #ff1b57 0%, #ff0085 100%);

    outline: none;
    color: #ffffff;
    font-size: 24px;
    font-family: 'Montserrat-Bold';
    line-height: 45px;
  }

  button:disabled {
    background: #ff85c4; 
    color: #efefef;
  }

  .gallery-crop {
    margin-top: .6rem;
    width: 80vw;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .gallery {
    display: inline-flex;
  }

  .gallery > figure {
    max-width: 170px;
    flex-shrink: 0;
    word-wrap: break-word;
  }

  .gallery > figure > img {
    max-width: inherit;
    border-radius: 30px;
    margin-bottom: 1rem;
  }


  @media screen and (max-width: 420px) {
    padding: 0 20px 26px;
    .card {
      padding: 20px;
      border-radius: 19px;
    }

    .avatar {
      margin-bottom: 18px;
      img {
        max-height: 272px;
        border-radius: 19px;
      }
      &:before {
        border-radius: 19px;
      }
      .profile-info {
        bottom: 20px;
        left: 27px;
        > span {
          font-size: 17px;
          line-height: 26px;
        }
        .name {
          font-size: 24px;
        }
      }
    }

    .desc {
      margin-bottom: 30px;

      h2 {
        margin: 0 0 6px;
        font-size: 17px;
        line-height: 26px;
      }

      p {
        font-size: 16px;
        line-height: 26px;
      }
    }

    .info {
      margin-bottom: 40px;

      .info-type {
        margin-bottom: 6px;
      }

      p {
        font-size: 17px;
        line-height: 25px;
      }
    }

    button {
      margin-bottom: 20px;
      padding: 10px;
      border-radius: 26px;
      box-shadow: 0px 2px 0px 0px rgba(253, 223, 51, 1),
        0px 8px 16px 0px rgba(255, 27, 87, 0.35);

      font-size: 17px;
      line-height: 31px;
    }
  }

  .underline:after {
    content: '';
    position: absolute;
    left: 0;
    top: 34px;
    width: 19%;
    height: 3px;
    background-color: #ff2978;
  }

  .pos-r {
    position: relative;
  }

  .photo-count {
    position: absolute;
    right: 20px;
    bottom: 22px;
    color: #fff;
  }

  @media screen and (max-width: 230px) {
    padding: 0 10px 13px;
    .card {
      padding: 10px;
      border-radius: 9px;
    }
    .avatar {
      margin-bottom: 16px;
      img {
        max-height: 138px;
        border-radius: 9px;
      }
      &:before {
        border-radius: 9px;
      }
      .profile-info {
        bottom: 9px;
        left: 12px;
        > span {
          font-size: 8px;
          line-height: 13px;
        }
        .name {
          font-size: 11px;
        }
      }
    }

    .desc {
      margin-bottom: 14px;

      h2 {
        margin: 0 0 3px;
        font-size: 8px;
        line-height: 13px;
      }

      p {
        font-size: 8px;
        line-height: 13px;
      }
    }

    .info {
      margin-bottom: 19px;

      img {
        width: 8px;
        height: 8px;
        margin-right: 10px;
        margin-top: 2px;
      }

      .info-type {
        margin-bottom: 3px;
      }

      p {
        font-size: 8px;
        line-height: 12px;
      }
    }

    button {
      margin-bottom: 10px;
      padding: 5px;
      border-radius: 12px;
      box-shadow: 0px 1px 0px 0px rgba(253, 223, 51, 1),
        0px 4px 8px 0px rgba(255, 27, 87, 0.35);

      font-size: 8px;
      line-height: 15px;
    }
  }
`;
