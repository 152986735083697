import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import Moment from 'moment';

import authService from '../services/authService';
import Modal from '../component/modal';
import Expired from '../component/expired';
import ChatHeader from '../component/chatHeader';
import ChatMessage from '../component/chatMessage';
import StickyInput from '../component/stickyInput';
import firebase from '../Firebase';

const Message = () => {
  // const [expireState, setExpireState] = useState(false);

  const [expireState, setExpireState] = useState(
    new Date(authService.expireDate) - new Date() <= 0 ? true : false,
  );

  const chatRef = useRef();
  const [hideName, sethideName] = useState(false);
  const [chats, setChats] = useState(false);
  const [newchat, setNewchat] = useState({
    matchId: '',
    userId: '',
    message: '',
    date: '',
    type: '',
  });

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const adjustHeight = () => setWindowHeight(window.innerHeight);
  const scrollToBottom = () => {
    const node = chatRef.current;
    node.scrollTop = node.scrollHeight;
  };

  const { matchId, userId } = useParams();

  useEffect(() => {
    window.addEventListener('resize', adjustHeight);
    return function cleanup() {
      window.removeEventListener('resize', adjustHeight);
    };
  }, []);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      firebase
        .database()
        .ref('match/' + matchId)
        .on('value', resp => {
          if (isSubscribed) {
            setChats([]);
            setChats(snapshotToArray(resp));
          }
        });
    };

    fetchData();
    return () => (isSubscribed = false);
  }, [matchId]);

  useEffect(() => {
    if (chatRef.current.scrollHeight > chatRef.current.clientHeight) {
      sethideName(true);
    } else {
      sethideName(false);
    }
    scrollToBottom();
  }, [chats, windowHeight]);

  const snapshotToArray = snapshot => {
    const returnArr = [];
    snapshot.forEach(childSnapshot => {
      const item = childSnapshot.val();
      item.key = childSnapshot.key;
      returnArr.push(item);
    });

    return returnArr;
  };

  const submitMessage = e => {
    e.preventDefault();
    if (newchat.message) {
      const chat = newchat;
      chat.userId = userId;
      chat.matchId = matchId;
      chat.date = Moment(new Date()).format('DD/MM/YYYY HH:mm:ss');
      chat.type = 'message';
      const newMessage = firebase
        .database()
        .ref('match/' + matchId)
        .push();
      newMessage.set(chat);
      setNewchat({ matchId: '', userId: '', message: '', date: '', type: '' });
    }
  };

  const onChange = e => {
    e.persist();
    setNewchat({ ...newchat, [e.target.name]: e.target.value });
  };

  return (
    <MessageWrapper style={{ height: windowHeight }}>
      <Modal isOpen={expireState} overlay="white" position="bottom">
        <Expired handleClose={() => setExpireState(false)} />
      </Modal>
      <ChatHeader hideName={hideName} />
      <div ref={chatRef} id="chat-container" className="chat-container">
        {chats && chats.length === 0 && (
          <div className="chat-message--main chat-message">
            <div className="chat-message__text">
              <p>
                Great to see you! <br />
                Connect by sending messages through <b>Message Board</b> before
                time runs out. You will be notified when you get a reply.
              </p>
              <p className="footer">Hang in and have fun!</p>
              <p className="footer-name">MKB</p>
            </div>
            <img src="/images/mkb_avatar.svg" alt="avatar" />
          </div>
        )}
        {chats &&
          chats.map((item, idx) => (
            <ChatMessage
              key={item.key}
              item={item}
              position={item.userId === userId ? 'right' : 'left'}
            />
          ))}
      </div>
      <StickyInput
        value={newchat.message}
        submitMessage={submitMessage}
        onChange={onChange}
      />
    </MessageWrapper>
  );
};

export default Message;

const MessageWrapper = styled.div`
  max-width: 540px;
  margin: auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;

  .chat-container {
    overflow-y: auto;
  }

  .chat-message {
    display: flex;
    align-items: center;

    transition: all 0.5s;
    padding: 0 26px;

    &--right {
      flex-direction: row-reverse;
    }
  }

  .chat-message--main {
    .chat-message__text {
      padding: 40px 44px;
      max-width: 90%;

      background-color: rgba(255, 255, 255, 1);
      border: 2px solid rgba(240, 241, 249, 1);
      border-radius: 31px 31px 6px 31px;
      box-shadow: 0px 4px 2px 0px rgba(12, 9, 51, 0.01);
    }

    p {
      font-family: 'Montserrat-Light';
      color: rgba(16, 16, 16, 1);
    }

    b {
      font-family: 'Montserrat-SemiBold';
      color: rgba(16, 16, 16, 1);
    }

    .footer {
      margin-top: 15px;
      font-family: 'Montserrat-Medium';
      color: rgba(58, 58, 58, 1);
    }

    .footer-name {
      font-family: 'Montserrat-Medium';
      color: rgba(255, 27, 87, 1);
    }

    img {
      height: 48px;
      width: 48px;
      align-self: flex-end;
      margin-left: 12px;
    }
  }

  .chat-message__text {
    display: block;
    max-width: 65%;

    box-sizing: border-box;
    overflow-wrap: break-word;

    padding: 12px 16px;
    font: 19px 'Montserrat-Medium';
    line-height: 27px;
    color: #ffffff;
  }

  @media screen and (max-width: 420px) {
    .chat-message {
      padding: 0 18px;
    }

    .chat-message--main {
      .chat-message__text {
        padding: 28px 30px;

        border: 2px solid rgba(240, 241, 249, 1);
        border-radius: 22px 22px 4px 22px;
        box-shadow: 0px 3px 2px 0px rgba(12, 9, 51, 0.01);
      }

      .footer {
        margin-top: 8px;
      }

      img {
        height: 33px;
        width: 33px;
        margin-left: 8px;
      }
    }

    .chat-message__text {
      padding: 10px 18px;
      font-size: 13px;
      line-height: 19px;
    }
  }

  @media screen and (max-width: 230px) {
    .chat-message {
      padding: 0 9px;
    }

    .chat-message--main {
      .chat-message__text {
        padding: 13px 14px;

        border: 0px solid rgba(240, 241, 249, 1);
        border-radius: 10px 10px 2px 10px;
        box-shadow: 0px 1px 1px 0px rgba(12, 9, 51, 0.01);
      }

      .footer {
        margin-top: 4px;
      }

      img {
        height: 16px;
        width: 16px;
        margin-left: 4px;
      }
    }

    .chat-message__text {
      padding: 5px 8px;
      font-size: 6px;
      line-height: 9px;
    }
  }
`;
