import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import authService from './services/authService';

const PrivateRoute = ({ children, page }) => {
  const { matchId, userId } = useParams();
  const [response, setResponse] = useState();

  useEffect(() => {
    let isSubscribed = true;

    authService.isValidUser(matchId, userId, page).then(res => {
		console.log( '##########################' )
		console.log( res )
      isSubscribed && setResponse(res);
    });

    return () => (isSubscribed = false);
  }, [matchId, userId, page]);

  if (response === undefined) return <div></div>;

  return response ? children : <div>Not found</div>;
};

export default PrivateRoute;
