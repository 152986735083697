import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

export default props => {
  const [buttonState, setButtonState] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return function cleanup() {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () =>
    window.scrollY > 0 ? setButtonState(false) : setButtonState(true);

  return (
    <ButtonWrapper state={buttonState}>
      <div className="shadow" />
      <div className="btn-wrap">
        <button onClick={props.onClick}>Meet, connect, enjoy</button>
      </div>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  transition: all 0.3s ease-in-out;
  position: absolute;
  bottom: 0;
  width: 100%;

  .shadow {
    height: 100px;

    background-image: -webkit-linear-gradient(
      -180deg,
      transparent,
      #ffffff 100%
    );
    background-image: -moz-linear-gradient(-180deg, transparent, #ffffff 100%);
    background-image: -o-linear-gradient(-180deg, transparent, #ffffff 100%);
    background-image: linear-gradient(-180deg, transparent, #ffffff 100%);
  }

  visibility: ${props => (props.state ? 'visible' : 'hidden')};
  opacity: ${props => (props.state ? 1 : 0)};

  .btn-wrap {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 15px 0 60px;
  }

  button {
    width: 80%;
    max-width: 480px;
    margin: 0 auto;
    padding: 15px;
    border-radius: 38px;
    border: none;
    box-shadow: 0 3px 0px 0px #fddf33, 0 11px 23px 0px rgba(255, 27, 87, 0.35);
    background-image: -webkit-linear-gradient(-46deg, #ff1b57 0%, #ff0085 100%);
    background-image: -moz-linear-gradient(-46deg, #ff1b57 0%, #ff0085 100%);
    background-image: -o-linear-gradient(-46deg, #ff1b57 0%, #ff0085 100%);
    background-image: linear-gradient(-46deg, #ff1b57 0%, #ff0085 100%);

    outline: none;
    color: #ffffff;
    font-size: 24px;
    font-family: 'Montserrat-Bold';
    line-height: 45px;
  }

  @media screen and (max-width: 420px) {
    .shadow {
      height: 50px;
    }
    .btn-wrap {
      padding: 10px 0 40px;
    }
    button {
      padding: 10px;
      border-radius: 26px;
      box-shadow: 0px 2px 0px 0px rgba(253, 223, 51, 1),
        0px 8px 16px 0px rgba(255, 27, 87, 0.35);

      font-size: 17px;
      line-height: 31px;
    }
  }

  @media screen and (max-width: 230px) {
    .shadow {
      height: 25px;
    }
    .btn-wrap {
      padding: 5px 0 20px;
    }
    button {
      padding: 5px;
      border-radius: 12px;
      box-shadow: 0px 1px 0px 0px rgba(253, 223, 51, 1),
        0px 4px 8px 0px rgba(255, 27, 87, 0.35);

      font-size: 8px;
      line-height: 15px;
    }
  }
`;
