import React, { useRef } from 'react';
import styled from 'styled-components';

export default props => {
  const inputRef = useRef();

  return (
    <InputWrapper>
      <form
        className="chat-input-area"
        onSubmit={(e) => {
          e.preventDefault();
          props.submitMessage(e);
          inputRef.current.focus();
        }}
      >
        <input
          ref={inputRef}
          type="text"
          className="chat-input-area__input"
          placeholder="Type here"
          name="message"
          autoComplete="off"
          value={props.value}
          onChange={props.onChange}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              props.submitMessage(event);
            }
          }}
        />
        <button
          type="submit"
          className="chat-input-area__submit-button"
        ></button>
      </form>
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  // Chat input area
  .chat-input-area {
    position: relative;
    background: #fff;
    background-color: rgba(157, 160, 222, 0.09);
    border-radius: 30px;
    margin: 25px;
    padding: 8px 0;
  }

  .chat-input-area__input {
    background: none;
    border: none;
    color: #000000;
    font: 21px 'NunitoSans-Regular';
    margin: 0;
    outline: none !important;
    padding: 10px 30px;
    padding-right: 48px;
    resize: none;

    width: 100%;
    box-sizing: border-box;

    &:focus {
      outline: none;
    }
  }

  .chat-input-area__submit-button {
    position: absolute;
    top: 0;
    right: 16px;
    bottom: 0;
    width: 28px;
    height: 28px;
    background-color: transparent;
    background-image: url('/images/send_btn_web.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border: none;
    margin: auto;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  @media screen and (max-width: 420px) {
    .chat-input-area {
      border-radius: 21px;
      margin: 18px;
      padding: 4px 0;
    }

    .chat-input-area__input {
      font-size: 15px;
      line-height: 31px;
      padding: 6px 20px;
      padding-right: 40px;
    }

    .chat-input-area__submit-button {
      right: 14px;
      width: 20px;
      height: 20px;
    }
  }

  @media screen and (max-width: 230px) {
    .chat-input-area {
      border-radius: 10px;
      margin: 8px;
      padding: 3px 0;
    }

    .chat-input-area__input {
      font-size: 7px;
      line-height: 15px;
      padding: 3px 9px;
      padding-right: 20px;
    }

    .chat-input-area__submit-button {
      right: 7px;
      width: 10px;
      height: 10px;
    }
  }
`;
