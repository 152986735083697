import Api from './api';

class AuthService {
  userInfo = {};
  partnerInfo = {};
  expireDate = '';


  isValidOtp = async (kidId, otpCode) => {
	  const res = await Api.post(`api/chat/v1/kids/check-otp`, { kidId, otpCode })
	  return res.body ? res.body.success : false;
  }

  acceptTac = async (matchId, userId) => {
    const res = await Api.post(`api/chat/v1/kids/accept-tac/${matchId}/${userId}`)
    return res;
  }


  isValidUser = async (matchId, userId, page) => {
    const res = await Api.get(`api/chat/v1/channels/${matchId}/${userId}`);

    this.height_value = typeof res.body.data.height_value !== 'undefined'
       ? res.body.data.height_value
       : 'cm';
    // const res = {
    //   body: {
    //     data: {
    //       id: 1,
    //       kid_id: 231,
    //       channel_id: 1,
    //       link_id: 1,
    //       opened_at: '2020-10-08 08:34:23',
    //       link_code: 'wIcKh4fnhtLiF4GG',
    //       created_at: '2020-10-08T06:05:10.000000Z',
    //       updated_at: '2020-10-08T08:34:23.000000Z',
    //       channel: {
    //         id: 1,
    //         uuid: 'a1085bf6-d97b-4667-b90d-4af919f5ae89',
    //         favourite_id: 115,
    //         expires_at: '2020-10-23 08:34:23',
    //         created_at: '2020-10-08T06:05:10.000000Z',
    //         updated_at: '2020-10-08T08:34:23.000000Z',
    //         kids: [
    //           {
    //             id: 231,
    //             user_id: 255,
    //             name: 'John',
    //             surname: 'Wick',
    //             height: 150,
    //             dob: '1990-08-08T00:00:00.000000Z',
    //             age: 30,
    //             gender: 'female',
    //             religion: 'Atheist',
    //             address: 'US, NY',
    //             mobile: '+9779898654636',
    //             mobile_parts: {
    //               country_id: 170,
    //               calling_code: '977',
    //               mobile_number: '9898654636',
    //             },
    //             city_id: 15152,
    //             country_id: 170,
    //             description:
    //               'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    //             one_word_description: 'Compassion',
    //             fun_story:
    //               'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    //             family_values: 'Apple pie, Pad Thai',
    //             work: {
    //               description: 'Marketing & PR at Deloitte',
    //             },
    //             education: {
    //               description: 'MA in English',
    //             },
    //             created_at: '2020-08-25T14:31:03.000000Z',
    //             updated_at: '2020-08-25T14:32:03.000000Z',

    //             photos: [
    //               {
    //                 id: 118,
    //                 title: '0dbc977e-fee5-4302-85fd-c158a5d75607.jpg',
    //                 url: 'https://www.w3schools.com/howto/img_avatar.png',
    //                 data: [],
    //               },
    //               {
    //                 id: 119,
    //                 title: '0dbc977e-fee5-4302-85fd-c158a5d75607.jpg',
    //                 url: 'https://www.w3schools.com/howto/img_avatar.png',
    //                 data: [],
    //               },
    //               {
    //                 id: 120,
    //                 title: '0dbc977e-fee5-4302-85fd-c158a5d75607.jpg',
    //                 url: 'https://www.w3schools.com/howto/img_avatar.png',
    //                 data: [],
    //               },
    //               {
    //                 id: 121,
    //                 title: '0dbc977e-fee5-4302-85fd-c158a5d75607.jpg',
    //                 url: 'https://www.w3schools.com/howto/img_avatar.png',
    //                 data: [],
    //               },
    //             ],
    //             reg_stage: 4,
    //             height_unit_values: [
    //               {
    //                 unit: 'cm',
    //                 value: 150,
    //               },
    //               {
    //                 unit: 'inch',
    //                 value: 59.0551,
    //               },
    //             ],
    //           },
    //           {
    //             id: 294,
    //             user_id: 314,
    //             name: 'Tom',
    //             surname: 'Hanks',
    //             height: 169,
    //             dob: '1986-12-23T00:00:00.000000Z',
    //             age: 33,
    //             gender: 'male',
    //             religion: null,
    //             address: 'US, NY',
    //             mobile: '+9779841556633',
    //             mobile_parts: {
    //               country_id: 170,
    //               calling_code: '977',
    //               mobile_number: '9841556633',
    //             },
    //             city_id: 15152,
    //             country_id: 170,
    //             description:
    //               'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    //             one_word_description: 'Compassion',
    //             fun_story:
    //               'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    //             family_values: 'Apple pie, Pad Thai',
    //             work: {
    //               description: 'Marketing & PR at Deloitte',
    //             },
    //             education: {
    //               description: 'MA in English',
    //             },
    //             created_at: '2020-08-25T14:31:03.000000Z',
    //             updated_at: '2020-08-25T14:32:03.000000Z',
    //             // main_photo: {
    //             //   url: 'https://preview.redd.it/zk1caphiv7k51.png?width=1088&format=png&auto=webp&s=f7d2ac8bc471c866d348e68119386bdf03b51d12'
    //             // },
    //             photos: [
    //               {
    //                 id: 118,
    //                 title: '0dbc977e-fee5-4302-85fd-c158a5d75607.jpg',
    //                 url: 'https://www.w3schools.com/howto/img_avatar.png',
    //                 data: [],
    //               },
    //               {
    //                 id: 119,
    //                 title: '0dbc977e-fee5-4302-85fd-c158a5d75607.jpg',
    //                 url: 'https://www.w3schools.com/howto/img_avatar.png',
    //                 data: [],
    //               },
    //               {
    //                 id: 120,
    //                 title: '0dbc977e-fee5-4302-85fd-c158a5d75607.jpg',
    //                 url: 'https://www.w3schools.com/howto/img_avatar.png',
    //                 data: [],
    //               },
    //               {
    //                 id: 121,
    //                 title: '0dbc977e-fee5-4302-85fd-c158a5d75607.jpg',
    //                 url: 'https://www.w3schools.com/howto/img_avatar.png',
    //                 data: [],
    //               },
    //             ],
    //             reg_stage: 3,
    //             height_unit_values: [
    //               {
    //                 unit: 'cm',
    //                 value: 169,
    //               },
    //               {
    //                 unit: 'inch',
    //                 value: 66.5354,
    //               },
    //             ],
    //           },
    //         ],
    //       },
    //     },
    //     meta: {},
    //     success: true,
    //     message: 'success',
    //     status: 200,
    //     timestamp: '2020-10-09T11:28:07.502290Z',
    //   },
    // };

    if (res.body) {
      this.expireDate = this.expireDate
        ? this.expireDate
        : res.body.data.channel.expires_at;

      res.body.data.channel.kids.forEach(kid => {
        if (kid.id === res.body.data.kid_id) {
          this.userInfo = kid;
        } else {
          this.partnerInfo = kid;
        }
      });

      if (page === 'msg' && !this.expireDate) return false;

      return true;
    }

    return false;
  };

  setExpireDate = expireDate => (this.expireDate = expireDate);
}

export default new AuthService();
