import React from 'react';
import styled from 'styled-components';
// import Swiper core and required components
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/navigation/navigation.scss';

// install Swiper components
SwiperCore.use([Pagination, Navigation]);

export default props => {
  return (
    <SwiperWrapper>
      <Swiper
        navigation
        slidesPerView="1"
        spaceBetween={50}
        pagination={{ clickable: true }}
      >
	<SwiperSlide key={props.main_photo.id}>
            <div className="image-container">
              <img src={props.main_photo.url} alt="gallery" />
            </div>
        </SwiperSlide>

        {props.photos.map(photo => (
          <SwiperSlide key={photo.id}>
            <div className="image-container">
              <img src={photo.url} alt="gallery" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperWrapper>
  );
};

const SwiperWrapper = styled.div`
  .image-container {
    text-align: center;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .swiper-container {
    position: inherit;
  }

  .swiper-pagination-bullets {
    bottom: -56px;
  }

  .swiper-pagination-bullet {
    height: 10px;
    width: 10px;
    background-color: #fffef9;
    border-radius: 10px;
  }

  @media screen and (max-width: 420px) {
    .swiper-pagination-bullets {
      bottom: -42px;
    }
    .swiper-pagination-bullet {
      height: 5px;
      width: 5px;
      border-radius: 5px;
    }
  }

  @media screen and (max-width: 230px) {
    .swiper-pagination-bullets {
      bottom: -22px;
    }
    .swiper-pagination-bullet {
      height: 3px;
      width: 3px;
      border-radius: 3px;
    }
  }
`;
