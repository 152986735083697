import React from 'react';
import Moment from 'moment';
import styled from 'styled-components';

const ChatMessage = ({ item, position }) => {
  return (
    <Wrapper>
      <div className={`chat-message--${position} chat-message`}>
        <p className="chat-message__text">{item.message}</p>
      </div>
      <div className={`chat-message--${position} chat-message`}>
        <span className="timestamp">
          {Moment(item.date, 'DD/MM/YYYY HH:mm:ss').format('HH:mm A')}
        </span>
      </div>
    </Wrapper>
  );
};

export default ChatMessage;

const Wrapper = styled.div`
  .timestamp {
    line-height: 45px;
    font-family: 'NunitoSans-SemiBold';
    font-size: 16px;
    color: #d5d5d5;
  }

  .chat-message--left {
    .chat-message__text {
      background-color: rgba(255, 255, 255, 1);
      border-radius: 36px 36px 36px 6px;
      box-shadow: 0px 1px 0px 0px rgba(192, 198, 242, 1),
        0px 6px 12px 0px rgba(192, 198, 242, 0.33);

      color: rgba(58, 58, 58, 1);
    }
  }

  .chat-message--right {
    .chat-message__text {
      border-radius: 36px 36px 6px 36px;
      box-shadow: 0px 1px 0px 0px rgba(12, 9, 51, 1),
        0px 7px 9px 0px rgba(151, 154, 255, 0.64);
      background-color: rgba(118, 124, 255, 1);

      color: rgba(255, 255, 255, 1);
    }
  }

  @media screen and (max-width: 420px) {
    .timestamp {
      line-height: 31px;
      font-size: 11px;
    }
    .chat-message--left {
      .chat-message__text {
        border-radius: 25px 25px 25px 4px;
        box-shadow: 0px 1px 0px 0px rgba(192, 198, 242, 1),
          0px 4px 9px 0px rgba(192, 198, 242, 0.33);
      }
    }

    .chat-message--right {
      .chat-message__text {
        border-radius: 25px 25px 4px 25px;
        box-shadow: 0px 1px 0px 0px rgba(12, 9, 51, 1),
          0px 5px 7px 0px rgba(151, 154, 255, 0.64);
      }
    }
  }

  @media screen and (max-width: 230px) {
    .timestamp {
      line-height: 15px;
      font-size: 5px;
    }

    .chat-message--left {
      .chat-message__text {
        border-radius: 12px 12px 12px 2px;
        box-shadow: 0px 1px 0px 0px rgba(192, 198, 242, 1),
          0px 2px 4px 0px rgba(192, 198, 242, 0.33);
      }
    }

    .chat-message--right {
      .chat-message__text {
        border-radius: 12px 12px 2px 12px;
        box-shadow: 0px 1px 0px 0px rgba(12, 9, 51, 1),
          0px 2px 3px 0px rgba(151, 154, 255, 0.64);
      }
    }
  }
`;
