import React, { useState } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import Header from '../component/header';
import Modal from '../component/modal';
import Gallery from '../component/gallery';
import Welcome from '../component/welcome';
import Expired from '../component/expired';
import StickyButton from '../component/stickyButton';
import authService from '../services/authService';
import counterService from '../services/counterService';

const Profile = () => {
  const [welcomeState, setWelcomeState] = useState(window.newSession);
  // const [welcomeState, setWelcomeState] = useState(false);
  const [galleryState, setGalleryState] = useState(false);
  const [expireState, setExpireState] = useState(
    authService.expireDate && new Date(authService.expireDate) - new Date() <= 0
      ? true
      : false,
  );
  // const [expireState, setExpireState] = useState(false);

  const profileData = authService.userInfo;
  const partnerData = authService.partnerInfo;

  const { matchId, userId } = useParams();
  const history = useHistory();

  const clickChat = async () => {
    const data = await counterService.startCounter(matchId, userId);
    if (data) {
      !authService.expireDate && authService.setExpireDate(data.date);
      history.push(`/message/${matchId}/${userId}`);
    }
  };

  return (
    <>
      <ProfileWrapper>
        <Header />
        <div className="card">
          <div
            className="avatar"
            onClick={() => {
              partnerData.photos.length && setGalleryState(true);
            }}
          >
            <img
              src={
                partnerData.main_photo
                  ? partnerData.main_photo.url
                  : `/images/${partnerData.gender}_avatar.svg`
              }
              alt="avatar"
            />
            <div className="profile-info">
              <span className="name">
                {partnerData.name} {partnerData.surname}
              </span>
              <span className="age">Age {partnerData.age}</span>
              <span className="address">{partnerData.address}</span>
            </div>
          </div>
          <div className="desc">
            <h2>Mom says</h2>
            <p>{partnerData.description || '-'}</p>
          </div>
          <div className="desc">
            <h2>A fun story about {partnerData.name}</h2>
            <p>{partnerData.fun_story || '-'}</p>
          </div>
          <div className="desc">
            <h2>Three family values {partnerData.name} was raised on</h2>
            <p>{partnerData.family_values || '-'}</p>
          </div>
          <div className="desc">
            <h2>How would you describe {partnerData.name} in one word?</h2>
            <p>{partnerData.one_word_description || '-'}</p>
          </div>

          <div className="info">
            <div className="info-type">
              <img src="/images/icon_job.svg" alt="job" />
              <p className={!partnerData.work.description ? 'disabled' : ''}>
                {partnerData.work.description || 'No info'}
              </p>
            </div>
            <div className="info-type">
              <img src="/images/icon_study.svg" alt="study" />
              <p
                className={!partnerData.education.description ? 'disabled' : ''}
              >
                {partnerData.education.description || 'No info'}
              </p>
            </div>
            <div className="info-type">
              <img src="/images/icon_height.svg" alt="height" />
              <p className={!partnerData.height ? 'disabled' : ''}>
                {partnerData.height ? `${partnerData.height} cm` : 'No info'}
              </p>
            </div>
          </div>
          <div className="btn-wrap">
            <button onClick={clickChat}>Meet, connect, enjoy</button>
          </div>
        </div>

        <Modal
          isOpen={expireState}
          handleClose={() => setExpireState(false)}
          overlayBackground="rgba(194, 194, 194, 0.39)"
          position="bottom"
        >
          <Expired handleClose={() => setExpireState(false)} />
        </Modal>

        <Modal
          isOpen={galleryState}
          handleClose={() => setGalleryState(false)}
          overlayBackground="rgba(0, 0, 0, 0.75)"
          shouldCloseOnOverlayClick
        >
          <Gallery photos={partnerData.photos} />
        </Modal>

        <Modal
          isOpen={!expireState && welcomeState}
          handleClose={() => setWelcomeState(false)}
          overlayBackground="rgba(224, 224, 224, 0.48)"
        >
          <Welcome
            name={profileData.name}
            handleClose={() => setWelcomeState(false)}
          />
        </Modal>
      </ProfileWrapper>
      <StickyButton onClick={clickChat} />
    </>
  );
};

export default Profile;

const ProfileWrapper = styled.div`
  padding: 0 30px 39px;

  .card {
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 27px;
    max-width: 480px;
    margin: auto;
    padding: 30px;
  }

  .avatar {
    position: relative;
    margin-bottom: 24px;

    img {
      max-height: 412px;
      width: 100%;
      border-radius: 24px;
      vertical-align: middle;
      object-fit: cover;
    }

    &:before {
      background-image: -webkit-linear-gradient(
        -180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(30, 30, 30, 0.64) 100%
      );
      background-image: -moz-linear-gradient(
        -180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(30, 30, 30, 0.64) 100%
      );
      background-image: -o-linear-gradient(
        -180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(30, 30, 30, 0.64) 100%
      );
      background-image: linear-gradient(
        -180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(30, 30, 30, 0.64) 100%
      );
      border-radius: 24px;
      bottom: 0;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  .profile-info {
    bottom: 26px;
    left: 39px;
    position: absolute;
    > span {
      color: #fff;
      display: block;
      font-family: 'Montserrat-Regular';
      font-size: 24px;
      line-height: 38px;
    }
    .name {
      font-family: 'Montserrat-Bold';
      font-size: 34px;
    }
  }

  .desc {
    margin-bottom: 56px;
    overflow-wrap: break-word;

    h2 {
      margin: 0 0 10px;
      font-family: 'Montserrat-Medium';
      font-weight: 500;
      font-size: 24px;
      color: #101010;
      line-height: 38px;
    }

    p {
      font-size: 23px;
      color: #575757;
      line-height: 38px;
    }
  }

  .info {
    margin-bottom: 56px;

    img {
      width: 25px;
      height: 25px;
      margin-right: 38px;
      margin-top: 6px;
    }

    p {
      font-weight: 400;
      font-size: 24px;
      color: #101010;
      letter-spacing: 0.02em;
      line-height: 36px;
    }

    .info-type {
      display: flex;
      margin-bottom: 18px;
    }

    .disabled {
      font-family: 'Montserrat-Medium';
      color: #c5c5c5;
    }
  }

  a {
    text-decoration: none;
  }

  .btn-wrap {
    display: flex;
    flex-direction: column;
  }

  button {
    margin-bottom: 30px;
    padding: 15px;
    border-radius: 38px;
    border: none;
    box-shadow: 0 3px 0px 0px #fddf33, 0 11px 23px 0px rgba(255, 27, 87, 0.35);
    background-image: -webkit-linear-gradient(-46deg, #ff1b57 0%, #ff0085 100%);
    background-image: -moz-linear-gradient(-46deg, #ff1b57 0%, #ff0085 100%);
    background-image: -o-linear-gradient(-46deg, #ff1b57 0%, #ff0085 100%);
    background-image: linear-gradient(-46deg, #ff1b57 0%, #ff0085 100%);

    outline: none;
    color: #ffffff;
    font-size: 24px;
    font-family: 'Montserrat-Bold';
    line-height: 45px;
  }

  @media screen and (max-width: 420px) {
    padding: 0 20px 26px;
    .card {
      padding: 20px;
      border-radius: 19px;
    }

    .avatar {
      margin-bottom: 18px;
      img {
        max-height: 272px;
        border-radius: 19px;
      }
      &:before {
        border-radius: 19px;
      }
      .profile-info {
        bottom: 20px;
        left: 27px;
        > span {
          font-size: 17px;
          line-height: 26px;
        }
        .name {
          font-size: 24px;
        }
      }
    }

    .desc {
      margin-bottom: 30px;

      h2 {
        margin: 0 0 6px;
        font-size: 17px;
        line-height: 26px;
      }

      p {
        font-size: 16px;
        line-height: 26px;
      }
    }

    .info {
      margin-bottom: 40px;

      img {
        width: 17px;
        height: 17px;
        margin-right: 24px;
        margin-top: 3px;
      }

      .info-type {
        margin-bottom: 6px;
      }

      p {
        font-size: 17px;
        line-height: 25px;
      }
    }

    button {
      margin-bottom: 20px;
      padding: 10px;
      border-radius: 26px;
      box-shadow: 0px 2px 0px 0px rgba(253, 223, 51, 1),
        0px 8px 16px 0px rgba(255, 27, 87, 0.35);

      font-size: 17px;
      line-height: 31px;
    }
  }

  @media screen and (max-width: 230px) {
    padding: 0 10px 13px;
    .card {
      padding: 10px;
      border-radius: 9px;
    }
    .avatar {
      margin-bottom: 16px;
      img {
        max-height: 138px;
        border-radius: 9px;
      }
      &:before {
        border-radius: 9px;
      }
      .profile-info {
        bottom: 9px;
        left: 12px;
        > span {
          font-size: 8px;
          line-height: 13px;
        }
        .name {
          font-size: 11px;
        }
      }
    }

    .desc {
      margin-bottom: 14px;

      h2 {
        margin: 0 0 3px;
        font-size: 8px;
        line-height: 13px;
      }

      p {
        font-size: 8px;
        line-height: 13px;
      }
    }

    .info {
      margin-bottom: 19px;

      img {
        width: 8px;
        height: 8px;
        margin-right: 10px;
        margin-top: 2px;
      }

      .info-type {
        margin-bottom: 3px;
      }

      p {
        font-size: 8px;
        line-height: 12px;
      }
    }

    button {
      margin-bottom: 10px;
      padding: 5px;
      border-radius: 12px;
      box-shadow: 0px 1px 0px 0px rgba(253, 223, 51, 1),
        0px 4px 8px 0px rgba(255, 27, 87, 0.35);

      font-size: 8px;
      line-height: 15px;
    }
  }
`;
